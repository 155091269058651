<template>
  <div class="sign">
     
<br />
      <div id="newForm">
        <template v-if="!manualupload">
          <SearchableSign :image="image" @setImage="setImage($event)"/>
        </template>
           
        <v-btn v-model="manualupload" color="primary" @click="manualToggle()" class="mt-2">
        {{manualupload ? 'Search Database' : 'Upload Manually'}} 
        </v-btn>
         
         <v-container class="px-0" fluid >
            
            <div v-if="manualupload">
             <v-file-input accept="image/*" label="Sign Image" outlined v-model="image"/>
             <v-text-field v-model="name" label="[Code] Description (e.g. [R1-1] Stop Sign)" outlined clearable></v-text-field>
            </div>
         </v-container>
         
          <div align="center">
          <v-img :src="url" max-height="350" max-width="500" v-if="url" contain></v-img>
        </div>
          <v-text-field v-model="width" label="Sign Width" outlined clearable type="number"></v-text-field>
          <v-text-field v-model="thickness" label="Thickness" outlined></v-text-field>

          <v-select v-model="sheeting" :items="sheetingopts" label="Sheeting" dense outlined></v-select>
          <v-select v-model="sheetingcolors" multiple :items="sheetingcoloropts" label="Sheeting Color(s)" dense outlined></v-select>
            
          <v-select v-model="film" :items="filmopts" label="Film" dense outlined></v-select>
          <v-select v-model="filmcolors" multiple :items="filmcoloropts" label="Film Color(s)" dense outlined></v-select>
        
          <v-combobox v-model="substrate" :items="substrateopts" label="Substrate" dense outlined></v-combobox>
        
          <v-select v-model="mounting" :items="mountingopts" label="Mounting" dense outlined></v-select>
          <v-btn color="primary" @click="addSign()">Add Sign to Document</v-btn>
      </div>

       <table class="table" width="100%" v-if="signs.length">
          <thead>
              <th>Item #</th>
              <th>Preview</th>
              <th>Width (Thickness)</th>
              <th>Details</th>
              <th></th>
          </thead>
          <tbody>
              <template v-for="(sign, index) in signs">
                  <tr :key="index">
                      <td>{{ index+1 }}</td>

                      <td align="center" >
                          <v-img :src="sign.url" max-height="150" max-width="250" class="mx-auto" contain></v-img>
                      </td>

                      <td>{{ sign.width }} in. ({{ sign.thickness }} in.) </td>

                      <td>
                          <strong>Name:</strong> {{ sign.name }} <br />
                          <strong>Sheeting:</strong> {{ sign.sheeting }} <br />
                          <strong>Film:</strong> {{ sign.film }} <br />
                          <strong>Substrate:</strong> {{ sign.substrate }} <br />
                          <strong>Mounting:</strong> {{ sign.mounting }} <br />
                      </td>

                      <td>
                          <v-btn color="error" @click="deleteSign(index)">Delete</v-btn>
                      </td>
                  </tr>
              </template>
          </tbody>
      </table>
   
  </div>
</template>

<script>
// @ is an alias to /src
import opts from '../data/signselectors.json';
// import stopsign from '../assets/signs/R1-1 STOP 1-1 2004.png'
// import oncomingsign from '../assets/signs/R1-2a TO ONCOMING TRAFFIC 1-3 2004.png'
// import crossingsign from '../assets/signs/R1-6 IN-STREET PEDESTRIAN CROSSING 1-8 2004.png'
// import uturnsign from '../assets/signs/R3-4.png'
import SearchableSign from './SearchableSign'
import axios from 'axios'
export default {
  name: 'Sign',
    props: ['signs'],
    components: {SearchableSign},
    created() { 
        this.sheetingopts = opts.sheeting.map(s => s.sheetingname)
        this.sheeting = opts.sheeting.filter(s => s.isDefault)[0]["sheetingname"];

        this.filmopts = opts.film.map(f => f.filmname)
        this.film = opts.film.filter(f => f.isDefault)[0]["filmname"];

        this.substrateopts = opts.substrate.map(ss => ss.substratename)
        this.substrate = opts.substrate.filter(ss => ss.isDefault)[0]["substratename"];

        axios.get(`${process.env.VUE_APP_SIGN_API}/signs`).then(response => {            
            response.data.forEach(s => {
                this.signimageopts.push({
                    sId: s.signid,
                    signname: `${s.signdescription} [${s.signcode}]`
                })
            })
        })

    },
    data() {
        return {
            image: null,
            mimage: null,
            signimageopts: [],
            // ],
            width: 0,
            url: null,
            sheeting: null,
            sheetingcolors: null,
            film: null,
            filmcolors: null,
            substrate: null,
            substrateopts: [],
            mounting: 'Ground Mount',
            sheetingopts: [],
            sheetingcoloropts: [],
            filmopts: [],
            filmcoloropts: [],
            mountingopts: opts.mounting,
            name: '',
            thickness: .080.toFixed(3),
            manualupload: false
        }
    },
    watch: {
        sheeting: function () {
            this.getDefaults("sheeting");
        },
        film: function () {
            this.getDefaults("film");
        },
        width: function () {
            (this.width >= 48) ? this.thickness = .100.toFixed(3) : this.thickness = .080.toFixed(3)
        },
        image: function () {
            if (this.image) this.previewImage()
        }
    },
    computed: {
    },
    methods: {
        setImage (img) {
            this.image = img;
            this.name = `[${img.signcode}] ${img.signdescription}`
            
        },
        getDefaults(type) {
            var sObj = opts[type].filter(s => {         
                            if(s[type+'name'] == this[type]) return s;
                        });

            if(sObj.length){
                this[type+'coloropts'] = sObj[0].colors
                this[type+'colors'] = [sObj[0].defaultColor || null]
            }else {
                this[type+'coloropts'] = []
                this[type+'colors'] = []
            }
        },
        previewImage() {
            if (!this.manualupload){
            axios.get(`${process.env.VUE_APP_SIGN_API}/signs/image/${this.image.signid}`)
                    .then(response => this.url = response.data)
            } else {
                this.url = URL.createObjectURL(this.image)
            }
        },
        addSign() {
            
            if(this.image){
                this.signs.push({
                    image: this.image,
                    url: this.url,
                    width: this.width,
                    thickness: this.thickness,
                    sheeting: this.sheeting,
                    sheetingcolors: this.sheetingcolors.filter(n => n),
                    film: this.film,
                    filmcolors: this.filmcolors.filter(n => n),
                    substrate: this.substrate,
                    mounting: this.mounting,
                    name: this.name
                });
                this.image = null;
                this.width = 0;            
                this.sheeting = opts.sheeting.filter(s => s.isDefault)[0]["sheetingname"];
                this.getDefaults("sheeting");
                this.film = opts.film.filter(f => f.isDefault)[0]["filmname"];
                this.getDefaults("sheeting");
                this.substrate = opts.substrate.filter(ss => ss.isDefault)[0]["substratename"];
                this.mounting = 'Ground Mount';
                this.url = null;
                this.name = '';
            } else {
                alert("You did not add an image!");
            }   
        },
        deleteSign: function (idx) {
            if (confirm(`Are you sure you want to delete this sign? This cannot be undone.`)) {

                this.signs.splice(idx, 1)
            }
        },
        manualToggle: function () {
            this.manualupload = !this.manualupload
        }
    }
  }
</script>

<style>
td {
    text-align: center;
    vertical-align: middle;
    place-items: center;
}
</style>
