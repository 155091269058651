<template>
  <v-container>

    <div class="text-h2 text-center">Project Overview</div><br />
    <v-text-field v-model="date" label="Date" outlined clearable></v-text-field>
    <v-text-field v-model="ordernumber" label="Order #" outlined clearable></v-text-field>
    <v-text-field v-model="contractidnumber" label="Contract ID No." outlined clearable></v-text-field>
    <v-text-field v-model="fhwa" label="FHWA" outlined clearable></v-text-field>
    <v-text-field v-model="projectid" label="Project" outlined clearable></v-text-field>
    <v-select
          :items="districtitems"
          outlined
          v-model="district"
          label="District"
        ></v-select>
    <v-textarea outlined name="input-7-4" label="Submitted To" v-model="submittedto"></v-textarea>
    <v-text-field v-model="itemnumber" label="Item" outlined clearable></v-text-field> 
    <v-textarea outlined multi name="input-7-4" label="Submitted By" v-model="submittedby"></v-textarea>


    <div class="text-h2 text-center">Add Sign To Deliverable</div>
    <Sign :signs="signs" />
    <br />
    <v-divider></v-divider>
    <br />
    <v-overlay :value="submitted" >
    <v-container class="d-flex">
    <v-progress-circular
        indeterminate
        size="270"
        color="primary"
      ><v-img small contain max-width="250" max-height="150" src="../assets/ps-logo.png"></v-img></v-progress-circular></v-container></v-overlay>
    <v-text-field v-model="filename" label="Filename" outlined clearable></v-text-field>
    <v-btn @click="submitHandler" color="info">Download PDF</v-btn>
    {{ this.signs.length }} sign(s) have been added. 
    
  </v-container>
</template>

<script>
import {
  jsPDF
} from "jspdf";
import Sign from '../components/Sign.vue'
import vdotimage from "../assets/vdot.png"
import branding from "../assets/branding.png"
export default {
  name: 'Deliverable',
  components: {
    Sign
  },
  created() {
    
    this.date = this.getCurrentDate('pretty')
    
    this.filename = this.getCurrentDate() + '.pdf';
  },
  data() {
    return {
      ordernumber: '',
      projectid: '',
      fhwa: '',
      contractidnumber: '',
      itemnumber: '50108',
      submittedto: '',
      submittedby: 'Robbie Morris\nPerformance Signs, LLC\n18 Commerce Drive\nRuckersville, VA 22968',
      signs: [],
      vdotimage: vdotimage,
      filename: '',
      district: null,
      branding: branding,
      date: null,
      submitted: false,
      districtitems: ['Bristol','Salem','Lynchburg','Richmond','Hampton Roads','Fredericksburg','Culpeper','Staunton','Northern VA']
    }
  },
  watch: {
    ordernumber: function(val) {
      
      let date = this.getCurrentDate();
      if(val){
        this.filename = `${val}-${date}.pdf`;
      } else {
        this.filename = `${date}.pdf`;
      }
    }
  },
  methods: {
    getCurrentDate (format) {
      const current = new Date();
      let month = current.getMonth()+1;
      let day = current.getDate();
      if (month < 10) month = '0'+month;
      if (day < 10) day = '0'+day;
      var date;
      if (format == 'pretty'){
        date = ('0' + (current.getMonth()+1)).slice(-2) + '/'
                  + ('0' + current.getDate()).slice(-2) + '/'
                  + current.getFullYear();
      }else{
        date = `${current.getFullYear()}${month}${day}`
      }
      return date;
    },
    stringHelper (str) {
      const lineArr = str.split("\n");
      var finalString = "";
      lineArr.forEach(line => {
        const strArr = line.split(" ")
        var charCount = 0;
        strArr.forEach(word => {
          charCount += word.length;
          if(charCount >= 20){
            finalString += "\n" + word + " ";
            charCount = word.length;
          }else{
            finalString += word + " ";
          }
        })
        finalString += "\n"
      })
      

      return finalString;
    },
    submitHandler () {
      if (this.signs.length){
        this.submitted = true;
        setTimeout(function () {
          // this.submitted = false;
          this.downloadPDF();
        }.bind(this),1000)
      }else {
        this.$toast.warning("Add a sign first!");
      }
    },
    downloadPDF () {      
      try{
      this.$plausible.trackEvent('downloadpdf', { props: { num_signs_downloaded: this.signs.length }});
      const doc = new jsPDF({
        orientation: "landscape",
        format: [431.8, 279.4]
      });
      //cover
      doc.setFontSize(24);
      doc.text('Submittal Drawing For:', 90, 30);
      doc.text(`Order No.: ${this.ordernumber}`, 90, 49);
      doc.text(`Project: ${this.projectid}`, 90, 59);
      doc.text(`FHWA: ${this.fhwa}`, 90, 69);
      doc.text(`Contract ID No.: ${this.contractidnumber}`, 90, 79);
      doc.text(`Item ${this.itemnumber}`, 90, 89);

      doc.text(`Submitted To:`, 90, 111);
      doc.text(`${this.submittedto}`, 90, 129);

      doc.text(`Submitted By:`, 90, 185);
      doc.text(`${this.submittedby}`, 90, 203);

      //
      //page
      this.signs.forEach((sign, index) => {
        doc.addPage([431.8, 279.4], "landscape");   

        //lay down branding
        doc.addImage(this.branding, 'PNG', 0, 0, 431.8, 279.4, '','FAST')   

        //add image first so we can write over top if needed
          //if reading from url we'll need to create new image.
          //s3 presigned not working for some reason...
          let u;
          const regex = /\?.*$/;
          const url = sign.url.replace(regex, '');
          if(sign.url.includes('blob:')){
            u = url;
          }else{
            u = new Image();
            u.crossOrigin = "anonymous";
            u.src = url;
          }
          doc.addImage(u, 'PNG', 20, 30, 193, 250, '','FAST');

        doc.setFontSize(14);
        doc.addImage(this.vdotimage, 'PNG', 9, 7, 36, 25,'','FAST');
        doc.setFont(undefined, 'bold');
        doc.text(`Order No.: ${this.ordernumber}   Project #: ${this.projectid}    FHWA: ${this.fhwa}    Contract ID. No.: ${this.contractidnumber}`, 50, 20);

        doc.setFontSize(10);
        var offset = 65;
        sign.sheetingcolors.forEach((color, idx) => {
          doc.setFont(undefined, 'bold');
          var i;
          sign.sheetingcolors.length > 1 ? i = `#${idx+1}` : i = '';
          doc.text(`Sheeting ${i}`, 250, offset)
          doc.setFont(undefined, 'normal');
          doc.text(`${sign.sheeting} ${color || ''}`, 275, offset);
          offset += 6;
        })
        
        sign.filmcolors.forEach((color, idx) => {
          doc.setFont(undefined, 'bold');
          var i;
          sign.filmcolors.length > 1 ? i = `#${idx+1}` : i = '';
          doc.text(`Film ${i}`,250,offset)
          doc.setFont(undefined, 'normal');
          doc.text(`${sign.film} ${color || ''}`, 275, offset);
          offset += 6;
        })


        

        doc.setFont(undefined, 'bold');
        doc.text('Substrate', 250, offset)
        doc.text('Thickness', 250, offset+6)
        doc.text('Sign Width', 250, offset+12)
        doc.text('Mounting', 250, offset+18)

        doc.setFont(undefined, 'normal');
        doc.text(`${sign.substrate}`, 275, offset);
        doc.text(`${sign.thickness} in.`, 275, offset+6);
        doc.text(`${sign.width} in.`, 275, offset+12);
        doc.text(`${sign.mounting || 'N/A'}`, 275, offset+18);

        

        doc.setFontSize(16);
        doc.text('VIRGINIA DEPARTMENT OF', 282, 193);
        doc.text('TRANSPORTATION', 294, 200);
        doc.text('REVIEW OF WORKING DRAWINGS', 274, 207);
        doc.setFontSize(14);
        doc.text('Working drawings have been received in accordance', 254, 214);
        doc.text('with Section 105.02 of the Specifications with the', 254, 220);
        doc.text('following comments:', 254, 226);
        //
        doc.rect(255, 231, 5, 5);
        doc.text('Review Completed.', 263, 235);
        doc.rect(255, 237, 5, 5);
        doc.text('Correct and Resubmit.', 263, 241);
        doc.rect(255, 243, 5, 5);
        doc.text('Rejected See Remarks.', 263, 247);
        //
        doc.text('Reviewed by',255,260 );
        doc.setLineWidth(1);
        doc.line(288, 260, 343, 260);
        doc.text('Date',344,260);
        doc.line(357, 260, 382, 260);
        doc.setLineWidth(0);
        if(this.district) doc.text(this.district.includes("Dist") ? `${this.district}` : `${this.district} Dist.`, 326, 235);


        //in graphic
        doc.setFontSize(14);
        doc.text(`${this.date}`, 387, 145)

        var submittedToFinal = this.stringHelper(this.submittedto);
        doc.text(`${submittedToFinal}`, 371, 110);
        //page num
        doc.setTextColor(255,255,255);
        doc.setFontSize(18)
        doc.text(`${index+1}`, 393, 166);

        doc.setTextColor(0,0,0);
      })
      doc.save(this.filename, {returnPromise:true}).then(this.submitted=false).then(this.$toast.success("Download Successful!"));
    }catch(err){
        this.submitted = false;
        this.$toast.error("Error! Try again later or contact the app administrator.");
      }
    }
  }
}
</script>